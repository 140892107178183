// is used at DropZone to get image rotation angle based on get-orientation library detection result.
export const ORIENTATION_TO_ANGLE = {
  '1': 0,
  '2': 0,
  '3': 180,
  '4': 0,
  '5': 0,
  '6': 90,
  '7': 0,
  '8': -90,
};

export type Flip = { horizontal: boolean; vertical: boolean };

export const MIME_TYPES_WITH_ORIENTATION_FIX_SUPPORTED = ['image/jpg', 'image/jpeg', 'image/tiff'];

export const MAX_IMAGE_SIZE_IN_PIXELS = 4096;
