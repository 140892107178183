import measureText from '../../utils/measureText';

// TODO: include the properties for building the text as part of each frame definition
const TEXT_BOX_COORDINATES = {
  width: 334,
  height: 90,
  left: 3,
  top: 361,
} as const;

const FONT_SIZE = 40;
const FONT_WEIGHT = '500';
const FONT_FAMILY = 'sans-serif';

const TEXT_MARGIN = 16;

export type TextProps = {
  value?: string;
  color?: string;
};

const buildSVGText = (config: TextProps): string => {
  const { value = '', color = 'white' } = config;

  const font = `${FONT_WEIGHT} ${FONT_SIZE}px ${FONT_FAMILY}`;

  const textWidth = Math.max(measureText(value, font)?.width || 0);

  const viewBoxWidth = textWidth > TEXT_BOX_COORDINATES.width ? textWidth : TEXT_BOX_COORDINATES.width;

  return `
<svg
  x="${TEXT_BOX_COORDINATES.left + TEXT_MARGIN}"
  y="${TEXT_BOX_COORDINATES.top}"
  width="${TEXT_BOX_COORDINATES.width - TEXT_MARGIN * 2}"
  height="${TEXT_BOX_COORDINATES.height}"
  viewBox="0 0 ${viewBoxWidth} ${TEXT_BOX_COORDINATES.height}">
    <text
      x="${viewBoxWidth / 2}"
      y="${TEXT_BOX_COORDINATES.height / 2}"
      text-anchor="middle"
      alignment-baseline="central"
      style="font-family: ${FONT_FAMILY}; font-weight: ${FONT_WEIGHT}; font-size: ${FONT_SIZE}px; fill: ${color}">
      ${value}
    </text>
</svg>
`;
};

export default buildSVGText;
