import { getOrientation } from 'get-orientation/browser';
import {
  Flip,
  MAX_IMAGE_SIZE_IN_PIXELS,
  MIME_TYPES_WITH_ORIENTATION_FIX_SUPPORTED,
  ORIENTATION_TO_ANGLE,
} from 'constants/images';

type PixelCrop = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Size = {
  width: number;
  height: number;
};

const DEFAULT_FLIP: Flip = { horizontal: false, vertical: false };

export const createImage = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.crossOrigin = 'anonymous';
    image.addEventListener('load', () => {
      window.URL.revokeObjectURL(image.src);
      resolve(image);
    });
    image.addEventListener('error', (error) => {
      reject(error);
    });

    if (file.type.includes('image/svg')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        image.src = e?.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      image.src = window.URL.createObjectURL(file);
    }
  });
};

export const createImageFromStringSrc = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export const getRadianAngle = (degreeValue: number): number => {
  return (degreeValue * Math.PI) / 180;
};

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export const rotateSize = (width: number, height: number, rotation: number): { width: number; height: number } => {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.floor(Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height)),
    height: Math.floor(Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)),
  };
};

export const exportFileFromCanvas = (imageSrc: File, canvas: HTMLCanvasElement): Promise<File> => {
  return new Promise((resolve) => {
    const type = imageSrc.type.includes('svg') ? 'image/png' : imageSrc.type;
    canvas.toBlob((file) => {
      if (file) {
        resolve(new File([file], imageSrc.name, { type }));
        return;
      }
      resolve(imageSrc);
    }, type);
  });
};

export const getCroppedImg = async (
  imageSrc: File,
  pixelCrop: PixelCrop = { x: 10, y: 10, width: 50, height: 50 },
  rotation = 0,
  flip = DEFAULT_FLIP
): Promise<File> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return imageSrc;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return exportFileFromCanvas(imageSrc, canvas);
};

export async function getRotatedImage(imageSrc: File, rotation = 0): Promise<File> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return imageSrc;
  }

  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx?.translate(canvas.width / 2, canvas.height / 2);
  ctx?.rotate((rotation * Math.PI) / 180);
  ctx?.drawImage(image, -image.width / 2, -image.height / 2);

  return exportFileFromCanvas(imageSrc, canvas);
}

export const fixImagesOrientation = async (files: File[]): Promise<File[]> => {
  const fixedImages = await Promise.allSettled(
    files.map(async (file) => {
      if (MIME_TYPES_WITH_ORIENTATION_FIX_SUPPORTED.includes(file.type)) {
        try {
          const orientation = await getOrientation(file);
          const rotation = ORIENTATION_TO_ANGLE[orientation];
          if (rotation) {
            return getRotatedImage(file, rotation);
          }
        } catch {
          return file;
        }
      }
      return file;
    })
  );

  return fixedImages
    .map((promiseResult) => {
      if (promiseResult.status === 'fulfilled') {
        return promiseResult.value;
      }
      return null;
    })
    .filter(Boolean) as File[];
};

export const fixImages = async (filesArg: File[]): Promise<File[]> => {
  let files = [...filesArg];
  try {
    // files = await fixImagesOrientation(files);
    files = await fixImagesSizeInPixels(files);
    // eslint-disable-next-line no-empty
  } catch {}
  return files;
};

export const getRestrictedSize = (size: Size, maxWidth: number, maxHeight: number): Size => {
  let { width, height } = size;

  if (width > height && width > maxWidth) {
    height *= maxWidth / width;
    width = maxWidth;
  } else if (height > maxHeight) {
    width *= maxHeight / height;
    height = maxHeight;
  }

  return { width, height };
};

export const resizeImage = async (
  imageSrc: File,
  maxWidth = MAX_IMAGE_SIZE_IN_PIXELS,
  maxHeight = MAX_IMAGE_SIZE_IN_PIXELS
): Promise<File> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return imageSrc;
  }

  const { width, height } = getRestrictedSize(image, maxWidth, maxHeight);

  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, Math.floor(width), Math.floor(height));

  return exportFileFromCanvas(imageSrc, canvas);
};

export const fixImagesSizeInPixels = async (files: File[]): Promise<File[]> => {
  const fixedImages = await Promise.allSettled(
    files.map(async (file) => {
      if (file.type.includes('image/') && !file.type.includes('svg')) {
        try {
          return resizeImage(file);
        } catch {
          return file;
        }
      }
      return file;
    })
  );

  return fixedImages
    .map((promiseResult) => {
      if (promiseResult.status === 'fulfilled') {
        return promiseResult.value;
      }
      return null;
    })
    .filter(Boolean) as File[];
};

/**
 * Checks whether an image URL fails to load
 */
export const getIsImageInvalid = async (imageURL: string) => {
  const response = await fetch(imageURL);
  return !response.ok;
};
