const changeElementTagType = (
  oldElement: Element,
  newTagName: keyof SVGElementTagNameMap | keyof HTMLElementTagNameMap
) => {
  const newElement = document.createElement(newTagName);
  const attributeList = oldElement.attributes;
  for (let i = 0; i < attributeList.length; i += 1) {
    const attribute = attributeList[i];
    newElement.setAttribute(attribute.name, attribute.value);
  }
  while (oldElement.firstChild) {
    newElement.appendChild(oldElement.firstChild);
  }
  oldElement.parentNode?.replaceChild(newElement, oldElement);

  return newElement;
};

const replaceElementWithChildren = (element: Element) => {
  const parent = element.parentNode;
  while (element.firstChild) {
    parent?.insertBefore(element.firstChild, element);
  }
  parent?.removeChild(element);
};

const replaceClipPathWithGroup = (element: Element, SVGDocument: Document) => {
  const fill = element.getAttribute('fill');
  const stroke = element.getAttribute('stroke');
  const clipPathAttr = element.getAttribute('clip-path');
  const clipPathIdMatch = clipPathAttr?.match(/#(\w+-?)*/gi);
  const clipPathId = clipPathIdMatch?.length ? clipPathIdMatch[0] : undefined;

  if (clipPathId) {
    const clipPath = SVGDocument.querySelector(clipPathId);
    if (clipPath) {
      const g = changeElementTagType(clipPath, 'g');
      if (fill) {
        g.setAttribute('fill', fill);
      }
      if (stroke) {
        g.setAttribute('stroke', stroke);
      }
    }
  }

  element.remove();
};

/**
 * Converts clipping paths to regular shape groups to improve compatibility with various software supporting SVG importing.
 */
export const improveQRCodeSVG = (SVGString: string) => {
  const SVGDocument = new DOMParser().parseFromString(SVGString, 'image/svg+xml');
  const defs = SVGDocument.querySelector('defs');

  if (defs) {
    replaceElementWithChildren(defs);
  }

  const elementReferencingClipPathList = SVGDocument.querySelectorAll('*[clip-path]');

  elementReferencingClipPathList.forEach((element) => replaceClipPathWithGroup(element, SVGDocument));

  const serializer = new XMLSerializer();

  const serializedSVG = serializer.serializeToString(SVGDocument);

  // Remove xmlns attributes added by XML serializer
  return serializedSVG.replaceAll(/ \w+="http:\/\/www.w3.org\/\d+\/(svg|xhtml)" /g, ' ');
};
