export type QRBoxProps = {
  qrCode?: string;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  style?: string;
};

const buildQRBox = (config: QRBoxProps): string => {
  const { qrCode = '', left = 65, top = 65, width = 210, height = 210, style = '' } = config;

  return `
    <svg x="${left}" y="${top}" width="${width}" height="${height}" style="${style}">
      ${qrCode}
    </svg>
  `;
};

export default buildQRBox;
