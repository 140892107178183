import buildQRBox from './shared/buildQRBox';
import buildSVGText from './shared/buildSVGText';
import { TemplateFunction } from './types';

const first: TemplateFunction = ({
  text = '',
  qrCode = '',
  color = 'black',
  background = 'none',
  qrContainerBackground = 'none',
  textColor,
  styleBlock = '',
}) => {
  const QRBox = buildQRBox({
    qrCode,
  });

  const SVGText = buildSVGText({
    value: text,
    color: textColor,
  });

  return `
    <svg viewBox="0 0 340 454" width="340" height="454" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${styleBlock}
      <rect y="0" x="0" width="340" height="454" />
      <rect x="3" y="3" width="334" height="334" rx="19" fill="${background}" stroke="${color}" stroke-width="4"/>
      <rect x="45" y="45" width="250" height="250" rx="20" fill="${qrContainerBackground}"/>
      ${QRBox}
      ${SVGText}
    </svg>
  `;
};

export default first;

// shape-rendering="crispEdges"
