import buildQRBox from './shared/buildQRBox';
import { TemplateFunction } from './types';

const defaultTemplate: TemplateFunction = ({
  qrCode = '',
  color = 'black',
  background = 'none',
  qrContainerBackground = 'none',
  styleBlock = '',
}) => {
  const QRBox = buildQRBox({
    qrCode,
    left: 0,
    top: 0,
  });

  return `
    <svg viewBox="0 0 210 210" width="210" height="210" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="210" height="210" rx="10" fill="${qrContainerBackground}"/>
      ${styleBlock}
      ${QRBox}
    </svg>
  `;
};

export default defaultTemplate;
