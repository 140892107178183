import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { QRCODE_RASTERIZED_FORMAT_DOWNLOAD_DESIRED_WIDTH } from 'constants/constants';
import { QRCodeOptionsDTO } from 'api/qrCode/types';
import cloudinaryApi from 'api/cloudinary';
import { decodeSVGStringFromBase64, encodeSVGStringToBase64 } from 'utils';
import frameLibrary from 'frames';
import { ImageTypeValue } from 'types';
import { getIsImageInvalid } from 'utils/images';
import { improveQRCodeSVG } from './improveQRCodeSVG';

const crispEdgesDotTypes = new Set(['rounded', 'classy', 'classy-rounded', 'square', 'extra-rounded']);
const FRAME_TEMPLATE_WIDTH = 340;
const FRAME_TEMPLATE_HEIGHT = 454;

const getQRCodeSizes = (options: QRCodeOptionsDTO) => {
  const isDefaultFrame = options.frame.type === 'default';

  return {
    width: isDefaultFrame ? options.width : FRAME_TEMPLATE_WIDTH,
    height: isDefaultFrame ? options.height : FRAME_TEMPLATE_HEIGHT,
  };
};

type QRCodeGeneratorContextValue = {
  buildQRCode: (options: QRCodeOptionsDTO) => Promise<string>;
  download: (options: QRCodeOptionsDTO, fileName: string, extension: ImageTypeValue) => Promise<any>;
};

export const QRCodeGeneratorContext = createContext<QRCodeGeneratorContextValue | null>(null);

export const useQrCodeGeneratorContext = (): QRCodeGeneratorContextValue | null => {
  const context = useContext(QRCodeGeneratorContext);
  return context;
};

export const QRCodeGeneratorProvider: FC<unknown> = ({ children }) => {
  const buildQRCode = useCallback(async (options: QRCodeOptionsDTO): Promise<string> => {
    if (!options) {
      return '';
    }

    const { image, ...restOptions } = options;

    let imageURL = '';

    if (image.uploaded) {
      const cloudinaryImageURL = cloudinaryApi.buildResourceURL(image.uploaded);
      const isImageInvalid = await getIsImageInvalid(cloudinaryImageURL);
      if (!isImageInvalid) {
        imageURL = cloudinaryImageURL;
      }
    } else if (image.selected) {
      imageURL = image.selected;
    }

    const QRCodeStyling = (await import('qr-code-styling')).default;
    const qrCodeGenerator = new QRCodeStyling({ ...restOptions, image: imageURL });

    qrCodeGenerator.applyExtension((svg: SVGElement) => {
      if (!options.dotsOptions.type || crispEdgesDotTypes.has(options.dotsOptions.type)) {
        svg.setAttribute('shape-rendering', 'crispEdges');
      }
    });

    const SVGBlob = await qrCodeGenerator.getRawData('svg');

    if (!SVGBlob) {
      return '';
    }

    const SVGString: string = await new Promise((resolve) => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result as string);

      reader.readAsDataURL(SVGBlob);
    });

    const decodedSVG = `${decodeSVGStringFromBase64(SVGString)}`;

    const improvedSVG = improveQRCodeSVG(decodedSVG);

    const { frame, backgroundOptions } = options;

    const frameTemplate = frameLibrary[frame.type || 'default'];

    const framedQRCodeTemplateResult = frameTemplate({
      ...frame,
      qrContainerBackground: backgroundOptions.color,
      qrCode: improvedSVG,
    });

    return framedQRCodeTemplateResult;
  }, []);

  const download = useCallback(
    async (options: QRCodeOptionsDTO, fileName: string, extension: ImageTypeValue): Promise<any> => {
      const fileSaver = await import('file-saver').then((module) => module.default);

      const { width, height } = getQRCodeSizes(options);

      const deviceRatio = window?.devicePixelRatio || 1;
      const desiredWidth = QRCODE_RASTERIZED_FORMAT_DOWNLOAD_DESIRED_WIDTH;
      const desiredWidthRatio = Math.floor(desiredWidth / width);
      const desiredWidthRatioStable = desiredWidthRatio > 1 ? desiredWidthRatio : 1;
      const qualityScaleFactor = deviceRatio;

      const calculatedScalingFactor = desiredWidthRatioStable * qualityScaleFactor;

      const frameWidth = width * calculatedScalingFactor;
      const frameHeight = height * calculatedScalingFactor;

      const qrCode = await buildQRCode(options);
      const stringSVG = encodeSVGStringToBase64(qrCode);

      if (extension === 'svg') {
        fileSaver.saveAs(stringSVG, `${fileName}.${extension}`);

        return;
      }

      const image = new Image();

      image.crossOrigin = 'anonymous';

      image.height = frameWidth;
      image.width = frameHeight;

      image.onload = () => {
        const canvas = document.createElement('canvas');

        canvas.width = frameWidth;
        canvas.height = frameHeight;

        const context = canvas.getContext('2d');

        // firefox have an issue: APP-429
        if (context && extension === 'jpeg') {
          context.fillStyle = '#ffffff';
          context.fillRect(0, 0, canvas.width, canvas.height);
        }

        context?.drawImage(image, 0, 0, frameWidth, frameHeight);

        const dataURL = canvas.toDataURL(`image/${extension}`);

        fileSaver.saveAs(dataURL, `${fileName}.${extension}`);

        URL.revokeObjectURL(stringSVG);
      };

      image.src = stringSVG;
    },
    [buildQRCode]
  );

  const providerValue = useMemo(
    () => ({
      buildQRCode,
      download,
    }),
    [buildQRCode, download]
  );

  return <QRCodeGeneratorContext.Provider value={providerValue}>{children}</QRCodeGeneratorContext.Provider>;
};
