import { isSSR } from './index';

const defaultFont = '500 16px sans-serif';
let canvas = isSSR() ? undefined : window.document.createElement('canvas');
let context = canvas?.getContext('2d');

export type MeasureTextType = (text: string, font: string) => TextMetrics | undefined;

const measureText: MeasureTextType = (text, font) => {
  if (isSSR()) return undefined;

  if (!canvas) {
    canvas = window.document.createElement('canvas');
    context = canvas.getContext('2d');
  }

  if (!context) return undefined;

  context.font = font || defaultFont;

  const textMetrics = context?.measureText(text);

  context.font = defaultFont;

  return textMetrics;
};

export default measureText;
