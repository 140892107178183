import { FrameType } from 'api/qrCode/types';
import { TemplateFunction } from './types';
import first from './first';
import second from './second';
import third from './third';
import fourth from './fourth';
import fifth from './fifth';
import defaultTemplate from './default';

type FrameLibrary = {
  [keyName in FrameType]: TemplateFunction;
};

const frameLibrary: FrameLibrary = {
  '1': first,
  '2': second,
  '3': third,
  '4': fourth,
  '5': fifth,
  default: defaultTemplate,
};

export default frameLibrary;
